// Import Bootstrap
@import "bootstrap/scss/bootstrap";

// Variables
$primary: #c8a97e;
$secondary: #1a1a1a;
$light: #f8f9fa;
$dark: #212529;

// Global styles
body {
    font-family: 'Playfair Display', serif;
    color: $secondary;
}

// Navbar styles
.navbar {
    background-color: rgba(26, 26, 26, 0.9);
    transition: background-color 0.3s ease;
    padding: 1rem 2rem;

    .navbar-brand {
        color: $primary;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 0;
        
        .navbar-logo {
            height: 50px;
            width: auto;
            transition: transform 0.3s ease;
            
            &:hover {
                transform: scale(1.05);
            }
        }
        
        &:hover {
            color: darken($primary, 10%);
        }
    }

    .nav-link {
        color: white;
        margin: 0 1rem;
        transition: color 0.3s ease;
        
        &:hover {
            color: $primary;
        }
    }

    .dropdown-menu {
        background-color: rgba(26, 26, 26, 0.95);
        border: 1px solid rgba(255, 255, 255, 0.1);
        
        .dropdown-item {
            color: #fff;
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem;
            
            img {
                margin-right: 0.5rem;
                border-radius: 2px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
            }
            
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
}

.nav-link .bi-globe {
    margin-right: 0.5rem;
    font-size: 1.1em;
}

// Hero section
.hero {
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1532250327408-9bd6e0ce2c49');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    
    .hero-content {
        width: 100%;
        max-width: 800px;
        padding: 0 20px;
        margin: 0 auto;
        
        h1 {
            font-size: 4.2rem;
            margin-bottom: 1.5rem;
            font-weight: bold;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        }
        
        p {
            font-size: 1.8rem;
            margin-bottom: 2.5rem;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
        }
        
        .btn-primary {
            background-color: $primary;
            border-color: $primary;
            padding: 1.2rem 3.5rem;
            font-size: 1.3rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: all 0.4s ease;
            
            &:hover {
                background-color: darken($primary, 10%);
                border-color: darken($primary, 10%);
                transform: translateY(-3px);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            }
            
            &:active {
                transform: translateY(-1px);
            }
        }
    }
}

// Services section
.services {
    padding: 5rem 0;
    background-color: $light;
    
    h2 {
        color: $secondary;
        text-align: center;
        margin-bottom: 3rem;
        font-size: 2.5rem;
    }
    
    .service-card {
        text-align: center;
        padding: 2rem;
        transition: transform 0.3s ease;
        
        &:hover {
            transform: translateY(-10px);
        }
        
        i {
            font-size: 3rem;
            color: $primary;
            margin-bottom: 1.5rem;
        }
        
        h3 {
            color: $secondary;
            margin-bottom: 1rem;
            font-size: 1.8rem;
        }
        
        p {
            color: lighten($secondary, 20%);
            font-size: 1.1rem;
        }
    }
}

// Specialties section
.specialties {
    padding: 5rem 0;
    background-color: white;
    
    h2 {
        text-align: center;
        margin-bottom: 3rem;
        color: $secondary;
        font-size: 2.5rem;
    }
    
    .specialty-card {
        text-align: center;
        padding: 2rem;
        margin-bottom: 2rem;
        transition: all 0.3s ease;
        
        &:hover {
            transform: translateY(-5px);
            
            i {
                color: darken($primary, 10%);
            }
        }
        
        i {
            font-size: 2.5rem;
            color: $primary;
            margin-bottom: 1rem;
            transition: color 0.3s ease;
        }
        
        h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
            color: $secondary;
        }
        
        p {
            color: lighten($secondary, 20%);
            font-size: 1rem;
            line-height: 1.6;
        }
    }
}

// Events section
.events {
    padding: 5rem 0;
    background-color: $light;
    
    h2 {
        text-align: center;
        margin-bottom: 3rem;
        color: $secondary;
        font-size: 2.5rem;
    }
    
    .event-card {
        background-color: white;
        padding: 2rem;
        border-radius: 10px;
        margin-bottom: 2rem;
        transition: all 0.3s ease;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        
        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
        }
        
        h3 {
            color: $primary;
            margin-bottom: 1rem;
            font-size: 1.8rem;
        }
        
        p {
            color: lighten($secondary, 20%);
            margin-bottom: 0;
            font-size: 1.1rem;
        }
    }
    
    .partnership-card {
        text-align: center;
        padding: 2rem;
        
        i {
            font-size: 2.5rem;
            color: $primary;
            margin-bottom: 1rem;
        }
        
        h4 {
            color: $secondary;
            margin-bottom: 1rem;
            font-size: 1.5rem;
        }
        
        p {
            color: lighten($secondary, 20%);
            font-size: 1rem;
        }
    }
}

// Contact section
.contact {
    padding: 5rem 0;
    background-color: white;
    
    h2 {
        text-align: center;
        margin-bottom: 1rem;
        color: $secondary;
        font-size: 2.5rem;
    }
    
    p.lead {
        text-align: center;
        margin-bottom: 3rem;
        color: lighten($secondary, 20%);
    }
    
    .form-control {
        margin-bottom: 1.5rem;
        padding: 0.75rem;
        border: 1px solid lighten($secondary, 50%);
        
        &:focus {
            border-color: $primary;
            box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
        }
    }
    
    select.form-control {
        height: auto;
        padding: 0.75rem;
    }
    
    .btn-primary {
        width: 100%;
        padding: 1rem;
        font-size: 1.2rem;
        background-color: $primary;
        border-color: $primary;
        
        &:hover {
            background-color: darken($primary, 10%);
            border-color: darken($primary, 10%);
        }
    }
    
    .contact-info {
        background-color: $light;
        padding: 2rem;
        border-radius: 10px;
        
        h3 {
            color: $primary;
            margin-bottom: 1.5rem;
            font-size: 1.8rem;
        }
        
        p {
            margin-bottom: 1rem;
            color: $secondary;
            
            i {
                color: $primary;
                margin-right: 0.5rem;
            }
        }
        
        .text-muted {
            color: lighten($secondary, 30%) !important;
        }
    }
}

// Footer
.footer {
    padding: 3rem 0;
    background-color: $secondary;
    color: white;
    
    h4 {
        color: $primary;
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
    }
    
    p {
        color: lighten($secondary, 50%);
    }
    
    .social-links {
        a {
            color: white;
            margin-right: 1rem;
            font-size: 1.5rem;
            transition: color 0.3s ease;
            
            &:hover {
                color: $primary;
            }
        }
    }

    .footer-logo {
        height: 60px;
        width: auto;
        margin-bottom: 1.5rem;
    }
}

// Animations
[data-aos] {
    opacity: 0;
    transition-property: opacity, transform;
    
    &.aos-animate {
        opacity: 1;
    }
}

// Menus section
.menus {
    padding: 8rem 0;
    background-color: white;
    
    h2 {
        text-align: center;
        margin-bottom: 4rem;
        color: $secondary;
        font-size: 3rem;
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            width: 80px;
            height: 3px;
            background-color: $primary;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .nav-pills {
        justify-content: center;
        margin-bottom: 4rem;
        
        .nav-link {
            color: $secondary;
            font-size: 1.2rem;
            padding: 1rem 2rem;
            margin: 0 1rem;
            border-radius: 30px;
            transition: all 0.3s ease;
            
            &.active {
                background-color: $primary;
                color: white;
            }
            
            &:hover:not(.active) {
                color: $primary;
                background-color: rgba($primary, 0.1);
            }
        }
    }

    .menu-item {
        margin-bottom: 2rem;
        background: white;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.4s ease;
        
        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
            
            img {
                transform: scale(1.1);
            }
        }

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            transition: transform 0.4s ease;
        }

        h3 {
            padding: 1.5rem 1.5rem 0.5rem;
            margin: 0;
            color: $secondary;
            font-size: 1.8rem;
        }

        p {
            padding: 0 1.5rem 1.5rem;
            margin: 0;
            color: lighten($secondary, 20%);
            font-size: 1.1rem;
        }
    }
}

// Gallery section
.gallery {
    padding: 8rem 0;
    background-color: $light;
    
    h2 {
        text-align: center;
        margin-bottom: 4rem;
        color: $secondary;
        font-size: 3rem;
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            width: 80px;
            height: 3px;
            background-color: $primary;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .gallery-item {
        position: relative;
        overflow: hidden;
        height: 400px;
        
        &:hover {
            img {
                transform: scale(1.1);
            }
            
            &:after {
                opacity: 0.7;
            }
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.4s ease;
        }
        
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.6));
            opacity: 0;
            transition: opacity 0.4s ease;
        }
    }
}

// Testimonials section
.testimonials {
    padding: 8rem 0;
    background-color: white;
    
    h2 {
        text-align: center;
        margin-bottom: 4rem;
        color: $secondary;
        font-size: 3rem;
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            width: 80px;
            height: 3px;
            background-color: $primary;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .testimonial-card {
        background-color: $light;
        padding: 3rem;
        border-radius: 15px;
        margin-bottom: 2rem;
        transition: all 0.4s ease;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
        
        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
        }

        .stars {
            color: $primary;
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }

        p {
            font-size: 1.1rem;
            font-style: italic;
            color: $secondary;
            margin-bottom: 1.5rem;
            line-height: 1.8;
        }

        .client {
            color: $primary;
            font-weight: 600;
            font-size: 1.1rem;
        }
    }
}

// Special Events section
.special-events {
    padding: 8rem 0;
    background-color: $light;
    
    h2 {
        text-align: center;
        margin-bottom: 4rem;
        color: $secondary;
        font-size: 3rem;
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            width: 80px;
            height: 3px;
            background-color: $primary;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .event-card {
        background-color: white;
        padding: 3rem;
        border-radius: 15px;
        margin-bottom: 2rem;
        transition: all 0.4s ease;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
        height: 100%;
        
        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
            
            i {
                transform: scale(1.1);
                color: darken($primary, 10%);
            }
        }

        i {
            font-size: 3rem;
            color: $primary;
            margin-bottom: 1.5rem;
            transition: all 0.4s ease;
        }

        h3 {
            color: $secondary;
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }

        p {
            color: lighten($secondary, 20%);
            font-size: 1.1rem;
            line-height: 1.8;
            margin-bottom: 0;
        }
    }

    .partner-card {
        text-align: center;
        padding: 3rem;
        background-color: white;
        border-radius: 15px;
        margin-bottom: 2rem;
        transition: all 0.4s ease;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
        height: 100%;
        
        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
            
            i {
                transform: scale(1.1);
                color: darken($primary, 10%);
            }
        }

        i {
            font-size: 3rem;
            color: $primary;
            margin-bottom: 1.5rem;
            transition: all 0.4s ease;
        }

        h3 {
            color: $secondary;
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }

        p {
            color: lighten($secondary, 20%);
            font-size: 1.1rem;
            line-height: 1.8;
            margin-bottom: 0;
        }
    }
}

// Dietary Requirements section
.dietary-requirements {
    padding: 8rem 0;
    background-color: white;
    position: relative;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(to right, transparent, $primary, transparent);
        opacity: 0.3;
    }
    
    h2 {
        text-align: center;
        margin-bottom: 4rem;
        color: $secondary;
        font-size: 3rem;
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            width: 80px;
            height: 3px;
            background-color: $primary;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .lead {
        text-align: center;
        color: lighten($secondary, 20%);
        font-size: 1.3rem;
        margin-bottom: 4rem;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    .dietary-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 2rem;
        padding: 0 1rem;
    }

    .dietary-item {
        background-color: $light;
        padding: 3rem;
        border-radius: 15px;
        text-align: center;
        transition: all 0.4s ease;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
        
        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
            
            i {
                transform: scale(1.1);
                color: darken($primary, 10%);
            }
        }

        i {
            font-size: 2.5rem;
            color: $primary;
            margin-bottom: 1.5rem;
            transition: all 0.4s ease;
        }

        h3 {
            color: $secondary;
            font-size: 1.5rem;
            margin-bottom: 1rem;
            font-weight: 600;
        }

        p {
            color: lighten($secondary, 20%);
            font-size: 1.1rem;
            line-height: 1.8;
            margin-bottom: 0;
        }
    }

    .note {
        text-align: center;
        margin-top: 4rem;
        padding: 2rem;
        background-color: rgba($primary, 0.05);
        border-radius: 15px;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        
        i {
            color: $primary;
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
        
        p {
            color: $secondary;
            font-size: 1.1rem;
            margin-bottom: 0;
            font-style: italic;
        }
    }
}

// Floating WhatsApp button
.floating-whatsapp {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #25D366;
    color: white;
    border-radius: 50px;
    padding: 12px 25px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    z-index: 1000;

    .bi-whatsapp {
        font-size: 24px;
    }

    .whatsapp-text {
        display: none;
        font-size: 14px;
        white-space: nowrap;
    }

    &:hover {
        background-color: #128C7E;
        color: white;
        transform: translateY(-2px);
        padding-right: 30px;

        .whatsapp-text {
            display: inline;
        }
    }

    // Adaptation mobile
    @media (max-width: 768px) {
        bottom: 20px;
        right: 20px;
        padding: 12px;

        .bi-whatsapp {
            font-size: 20px;
        }

        &:hover {
            padding: 12px;
            
            .whatsapp-text {
                display: none;
            }
        }
    }
}

// Sea Escape Section
.sea-escape {
    padding: 100px 0;
    background: linear-gradient(rgba(26, 26, 26, 0.7), rgba(26, 26, 26, 0.7)), 
                url('../assets/images/lagoon-55-sunset.jpg');
    background-size: cover;
    background-position: center;
    color: white;

    .col-lg-6:first-child {
        padding-left: 0;
        margin-left: -30px;
        
        @media (max-width: 991px) {
            margin-left: 0;
            padding-left: 15px;
        }
    }

    img {
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.02);
        }
    }

    .section-title {
        color: $primary;
        margin-bottom: 1.5rem;
    }

    .lead {
        font-size: 1.25rem;
        margin-bottom: 2rem;
        color: #f8f9fa;
    }

    .sea-escape-features {
        margin: 2rem 0;

        .feature-item {
            display: flex;
            align-items: center;
            margin-bottom: 1.5rem;
            
            i {
                font-size: 1.5rem;
                color: $primary;
                margin-right: 1rem;
            }

            p {
                margin: 0;
                font-size: 1.1rem;
                color: #f8f9fa;
            }
        }
    }

    .btn-primary {
        padding: 12px 30px;
        font-size: 1.1rem;
        
        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 5px 15px rgba(200, 169, 126, 0.3);
        }
    }
} 